<template>
  <main class="main">
    <section v-if="!listNFTed" class="main__banner main-banner">
      <div class="main-banner__container container container--sm">
        <div class="main-banner__wrap">
          <div class="main-banner__card card">
            <h1 class="card__title">
              <span></span> A NEW DIMENSION TO PLAY IN
            </h1>

            <div class="card__body">
              <p>
                Zoofrenz 3D Models are here! Zoofrenz holders can download and
                use their playable avatars without restriction. Simply connect
                your wallet to the website to view your Apefrenz and download
                the corresponding VRM files.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewportWidth <= 975">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </section>

    <section v-else class="main__section main-section">
      <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <div class="main-section__container container">
        <div class="main-section__wrap">
          <h2 class="main-section__title section-title">
            <span>Your Collection</span>
          </h2>
          <div class="row">
            <div
              v-for="(item, index) in vrmItems"
              :key="index"
              class="col-md-3 col-sm-6 mb-4"
            >
              <div class="collection-card">
                <div class="collection-card__vrm">
                  <img :src="item.imgUrl" alt="" />
                </div>

                <div class="collection-card__text">
                  <h3 class="collection-card__title">{{ item.id }}</h3>
                  <a
                    v-on:click="downloadVRM(item.walletAddress, item.tokenId)"
                    class="collection-card__btn"
                  >
                    DOWNLOAD VRM
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewportWidth <= 975">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </section>
  </main>
  <verifying :show="showVerifying" />
  <!-- <verifying :show=true /> -->
</template>

<script>
import http from "../api/http";
import verifying from "./Verifying.vue";

export default {
  name: "ClaimPage",
  components: { verifying },
  methods: {
    updateListNFTed: function () {
      this.listNFTed = window.walletConnected;
    },
    renderVRM: function () {
      this.vrmItems = window.vrmItems;
    },

    async downloadVRM(walletAddress, tokenId) {
      try {
        this.showVerifying = true;
        await http.requestVRMURL(walletAddress, tokenId);
      } catch (error) {
        console.error(error); // or handle the error in any way you want
      } finally {
        this.showVerifying = false;
      }
    },
  },

  data() {
    return {
      showVerifying: false,
      vrmItems: window.vrmItems,
      listNFTed: window.walletConnected,
      viewportWidth: window.innerWidth,
    };
  },
  mounted() {
    this.renderVRM();
    window.addEventListener("wallet-connect-event", this.updateListNFTed);
    window.addEventListener("render-vrm-event", this.renderVRM);

    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
        document.body.className = "touch webp";
      } else {
        document.body.className = "pc webp";
      }
    });
    if (window.innerWidth <= 975) {
      document.body.className = "touch webp";
    } else {
      document.body.className = "pc webp";
    }
  },
  beforeUnmount() {
    window.removeEventListener("wallet-connect-event", this.updateListNFTed);
    window.removeEventListener("render-vrm-event", this.renderVRM);
    window.removeEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
        document.body.className = "touch webp";
      } else {
        document.body.className = "pc webp";
      }
    });
  },
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
