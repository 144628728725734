<template>
  <main class="main">
    <section class="main__section main-section">
      <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
        <div class="main-section__wrap">
          <h2 class="main-section__title section-title"><span>Terms & Conditions</span></h2> 
          <p>&nbsp;</p> 
        <div class="term__card card card--green">         
          <div class="card__body">
            <p>
              TERMS & CONDITIONS
            </p>
            <p>
              Apefrenz is a collection of digital artworks
              (NFTs) running on the Ethereum network. This website is only an
              interface allowing participants to exchange digital collectibles.
              Users are entirely responsible for the safety and management of
              their own private Ethereum wallets and validating all transactions
              and contracts generated by this website before approval.
              Furthermore, as the Apefrenz smart contract runs on the Ethereum
              network, there is no ability to undo, reverse, or restore any
              transactions.
            </p>
            <p>
              This website and its connected services are provided “as is” and
              “as available” without warranty of any kind. By using this website
              you are accepting sole responsibility for any and all transactions
              involving Apefrenz digital collectibles.
            </p>
            <p>
              OWNERSHIP 
            </p>
            <p>
              i. You Own the NFT. Each Apefrenz is an NFT on the
              Ethereum blockchain. When you purchase an NFT, you own the
              underlying Apefrenz, the Art, completely. Ownership of the NFT is
              mediated entirely by the Smart Contract and the Ethereum Network:
              at no point may we seize, freeze, or otherwise modify the
              ownership of any Apefrenz.
            </p>
            <p>
              ii. Personal Use. Subject to your continued compliance with these
              Terms, Zombot Studio Inc. grants you a worldwide, royalty-free
              license to use, copy, and display the purchased Art, along with
              any extensions that you choose to create or use, solely for the
              following purposes: (i) for your own personal, non-commercial use;
              (ii) as part of a marketplace that permits the purchase and sale
              of your Apefrenz / NFT, provided that the marketplace
              cryptographically verifies each Apefrenz owner’s rights to display
              the Art for their Apefrenz to ensure that only the actual owner
              can display the Art; or (iii) as part of a third party website or
              application that permits the inclusion, involvement, or
              participation of your Apefrenz, provided that the
              website/application cryptographically verifies each Apefrenz
              owner’s rights to display the Art for their Apefrenz to ensure
              that only the actual owner can display the Art, and provided that
              the Art is no longer visible once the owner of the Apefrenz leaves
              the website/application.
            </p>
            <p>
              iii. Commercial Use. Subject to your continued compliance with
              these Terms, Zombot Studio Inc. grants you an unlimited, worldwide
              license to use, copy, and display the purchased Art for the
              purpose of creating derivative works based upon the Art
              (“Commercial Use”). Examples of such Commercial Use would e.g. be
              the use of the Art to produce and sell merchandise products
              (T-Shirts etc.) displaying copies of the Art. For the sake of
              clarity, nothing in this Section will be deemed to restrict you
              from (i) owning or operating a marketplace that permits the use
              and sale of Apefrenzs generally, provided that the marketplace
              cryptographically verifies each Apefrenz owner’s rights to display
              the Art for their Apefrenz to ensure that only the actual owner
              can display the Art; (ii) owning or operating a third party
              website or application that permits the inclusion, involvement, or
              participation of Apefrenzs generally, provided that the third
              party website or application cryptographically verifies each
              Apefrenz owner’s rights to display the Art for their Apefrenz to
              ensure that only the actual owner can display the Art, and
              provided that the Art is no longer visible once the owner of the
              Purchased Apefrenz leaves the website/application; or (iii)
              earning revenue from any of the foregoing.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div v-if="viewportWidth <= 975">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "TermView",
  data() {
    return {
      viewportWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "why-page touch webp";
    } else {
      document.body.className = "why-page pc webp";
    }
    });
    if (window.innerWidth <= 975) {
      document.body.className = "why-page touch webp";
    } else {
      document.body.className = "why-page pc webp";
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "why-page touch webp";
    } else {
      document.body.className = "why-page pc webp";
    }
    });
  }
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
