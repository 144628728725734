<template>
  <main>
    <!-- Top -->
    <section class="home">
      <img src="img/home/home-bg-01.jpg" alt="" class="home__bg home__bg--01" />

      <img src="img/home/home-bg-02.png" alt="" class="home__bg home__bg--02" />

      <img src="img/home/home-bg-03.png" alt="" class="home__bg home__bg--03" />

      <div class="home__container container">
        <div class="home__wrap">
          <img src="img/home/logo.png" alt="" class="home__logo" />
          <div v-if="viewportWidth <= 975">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
          <h1 class="home__title section-title section-title--pink">
            Wildly<span>Different NFTs</span>
          </h1>

          <div class="home__media-row">
            <img src="img/home/home-img-01.png" alt="" class="home__media-img home__media-img--01" />

            <div class="home__video-card video-card">
              <div class="video-card__media">
                <video poster="img/home/video.jpg">
                  <source src="files/Apefrenz_Trailer.mp4" type="video/mp4" />
                </video>

                <a v-on:click="playVideo" class="video-card__btn-play btn-play"></a>
              </div>
            </div>

            <img src="img/home/home-img-02.png" alt="" class="home__media-img home__media-img--02" />
          </div>

          <div class="home__card card card--brown-light">
            <div class="row align-items-center">
              <div class="col-md-8">
                <h3 class="card__title">WE'RE READY TO <span>PLAY</span></h3>

                <div class="card__body">
                  <p>The Apefrenz 3D Avatars have arrived.</p>
                  <p>
                    Built to spec for the Metaverse of the future, our avatars
                    are game-ready from the get go. Unlike most other 3D
                    projects, all Zoofrenz come fully-rigged and optimized for
                    performance. Interoperability is literally in their bones -
                    that’s how they can get around so easily.
                  </p>
                  <p>
                    The only thing limiting where your Frenz can go is your
                    imagination.
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div class="card__img">
                  <img src="img/home/card-img.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="card card--green card--shadow-top text-center">
            <img src="img/home/card-decor.png" alt="" class="card__decor" />

            <h3 class="card__title__center">
              YOUR <span>INTEROPERABLE</span> <br />
              IDENTITY
            </h3>

            <div class="card__body">
              <p>
                Being a Zoofrenz holder is your ticket to realizing one of
                Web3’s loftiest dreams - a virtual identity that seamlessly
                persists across all places and spaces. Take your 2D Zoofrenz PFP
                out of Web2 and into our Metaverse platform using our 3D
                Avatars. Want to take your Zoofrenz outside with you to touch
                grass? We may have some merch coming down the line that can help
                with that...
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Space -->
    <section class="zoo-space">
      <div class="zoo-space__container container">
        <div class="zoo-space__wrap">
          <div class="row flex-wrap-reverse">
            <div class="col-md-6 col-xs-7">
              <div class="zoo-space__img">
                <img src="img/home/space.jpg" alt="" />

                <img src="img/home/space-extra.png" alt="" class="zoo-space__extra" />
              </div>
            </div>

            <div class="col-md-6 col-xs-5">
              <div class="zoo-space__text">
                <h2 class="zoo-space__title">
                  A <span>NEW</span> WEB3 STANDARD
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col-md-8">
              <div class="card card--red">
                <div class="card__body">
                  <p>
                    The NFT space is getting more crowded by the day. With 2D
                    NFTs, what you see is basically what you get, but for 3D
                    often the devil is in the (invisible) details. As
                    professionals, we’re often able to spot a lot of 3D red
                    flags the untrained eye can’t spot. But we’re not interested
                    in being the Web3 neighborhood watch. That’s why we
                    developed our own standard for 3D NFTs so you know what to
                    look for when making your own decisions.
                  </p>
                </div>

                <a href="./Features" class="card__btn card-btn">
                  Learn more →
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-faqs">
      <div class="section-faqs__container container">
        <div class="section-faqs__wrap">
          <h2 class="section-faqs__title">FREQUENTLY ASKED QUESTIONS</h2>

          <div class="section-faqs__row">
            <div class="section-faqs__col">
              <ul>
                <li class="mb-2">
                  <div class="toggle">
                    <button class="toggle__btn">
                      What is Zoofrenz? <span class="toggle__plus"></span>
                    </button>
                    <div class="toggle__content">
                      <p>
                        Zoofrenz is a Metaverse IP that started off with an NFT
                        collection of 6,666 meditating Apefrenz and has now
                        branched into many different areas of Web3.
                      </p>
                    </div>
                  </div>
                </li>

                <li class="mb-2">
                  <div class="toggle">
                    <button class="toggle__btn">
                      What are Apefrenz? <span class="toggle__plus"></span>
                    </button>
                    <div class="toggle__content">
                      <p>
                        Apefrenz are the first Zoofrenz NFT collection. The
                        Apefrenz are a collection of 6,666 meditating ape NFTs
                        with over 400 possible traits built on the blockchain.
                        Apefrenz generate $FRENSHIP that can be used to obtain
                        high-fidelity 3D Zoofrenz playable characters and more!
                      </p>
                    </div>
                  </div>
                </li>

                <li class="mb-2">
                  <div class="toggle">
                    <button class="toggle__btn">
                      What's the difference between Apefrenz 1.0 & 2.0?
                      <span class="toggle__plus"></span>
                    </button>
                    <div class="toggle__content">
                      <p href="https://zoofrenz.gitbook.io/info/apefrenz/1.0-vs-2.0-collection">
                        1.0 Is the original Apefrenz collection that has now
                        been deprecated. If you are new to the project, we
                        recommend buying a 2.0 Apefrenz to join the community.
                        See more information
                        <a v-on:click="
                          openURL(
                            'https://zoofrenz.gitbook.io/info/apefrenz/1.0-vs-2.0-collection'
                          )
                          " class="toggle__content__link">here</a>.
                      </p>
                    </div>
                  </div>
                </li>

                <li class="mb-2">
                  <div class="toggle">
                    <button class="toggle__btn">
                      What animal is the next collection going to be?
                      <span class="toggle__plus"></span>
                    </button>
                    <div class="toggle__content">
                      <p>
                        Now now, it wouldn't be any fun if we just spilled the
                        beans so easily, would it?
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="section-faqs__col">
              <div class="section-faqs__img">
                <img src="img/home/faqs.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div v-if="viewportWidth <= 975">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      viewportWidth: window.innerWidth,
    };
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (this.viewportWidth <= 975) {
        document.body.className = "home-page touch webp";
      } else {
        document.body.className = "home-page pc webp";
      }
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (this.viewportWidth <= 975) {
        document.body.className = "home-page touch webp";
      } else {
        document.body.className = "home-page pc webp";
      }
    });

    if (window.innerWidth <= 975) {
      document.body.className = "home-page touch webp";
    } else {
      document.body.className = "home-page pc webp";
    }

    const accordions = document.querySelectorAll(".toggle");
    if (accordions.length > 0) {
      accordions.forEach((el) => {
        el.addEventListener("click", (e) => {
          const self = e.currentTarget;
          const control = self.querySelector(".toggle__btn");
          const content = self.querySelector(".toggle__content");
          self.classList.toggle("_active");
          if (self.classList.contains("_active")) {
            control.setAttribute("aria-expanded", true);
            content.setAttribute("aria-hidden", false);
            content.style.maxHeight = `${content.scrollHeight}px`;
          } else {
            control.setAttribute("aria-expanded", false);
            content.setAttribute("aria-hidden", true);
            content.style.maxHeight = null;
          }
        });
      });
    }
  },
  methods: {
    playVideo: function () {
      const videoCard = document.querySelector(".video-card");
      if (videoCard) {
        const btnPlay = videoCard.querySelector(".video-card__btn-play");
        const video = videoCard.querySelector("video");
        if (video.paused === true) {
          video.play();
          btnPlay.classList.add("hide");
        } else {
          video.pause();
        }
        video.addEventListener("click", (e) => {
          e.preventDefault();
          if (video.paused === true && btnPlay.classList.contains("hide")) {
            video.play();
          } else {
            video.pause();
            btnPlay.classList.remove("hide");
          }
        });
      }
    },
    openURL: function (Url) {
      // window.open(Url, "_self");
      window.open(Url);
    },
  },
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
