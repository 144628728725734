import { createRouter, createWebHistory } from "vue-router";
import Main from "./components/Home.vue";
import Claim from "./components/Claim.vue";
import Features from "./components/Features.vue";
import Merch from "./components/Merch.vue";
import Why from "./components/Why.vue";
import Team from "./components/Team.vue";
import Term from "./components/Term.vue";
const routes = [
  { path: "/index.html", component: Main },
  { path: "/", component: Main },
  { path: "/Claim", component: Claim },
  { path: "/Features", component: Features },
  { path: "/Merch", component: Merch },
  { path: "/Why", component: Why },
  { path: "/Team", component: Team },
  { path: "/Term", component: Term },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  },
});

export default router;
