import { WarmWallet as WarmWalletABI } from "./abi/WarmWallet";
const contractWarmWallet = "0xC3AA9bc72Bd623168860a1e5c6a4530d3D80456c";
class WarmWallet {
  constructor() {}
  init(web3) {
    this.web3 = web3;
  }
  contractWarmWallet() {
    if (!this.contract) {
      this.contract = new this.web3.eth.Contract(
        WarmWalletABI.abi,
        contractWarmWallet
      );
    }
    return this.contract;
  }
  
  getColdWallets(address) {
    return new Promise((resolve, reject) => {
      const contract = this.contractWarmWallet();
      contract.methods
        .getColdWallets(address)
        .call()
        .then((x) => {
          // window.console.log("getColdWallets:", x);
          resolve(x);
        })
        .catch((err) => {
          // window.console.error("balanceOf:", err);
          reject(err);
        });
    });
  }
}
const warmWallet = new WarmWallet();
export { warmWallet as WarmWallet };
