import CryptoJS from "crypto-js";
import axios from "axios";
import web3 from "../web3/web3.js";

import VerifySignedMessage from "./protojs/VerifySignedMessage_pb.js";
import HttpResponseProto from "./protojs/HttpResponse_pb.js";
import RequestSignedMessage from "./protojs/RequestSignedMessage_pb.js";

const SERVER_URL = "https://dev-third-space-api.zoofrenz.com";
// const SERVER_URL = "http://192.168.0.4";
//JWT
const payload = {
  iss: "Zoofrenz",
  iat: Math.floor(Date.now() / 1000),
  exp: Math.floor(Date.now() / 1000) + 60 * 60 * 24, // expires in 1 day
  client_version: "0.0.3",
  client_bundle_id: "com.zoofrenz.ThirdSpace",
};
const secretKey = "46d36f16692f1b3b6f1165a4478c4b90";
// Encode the JWT header and payload using base64
const header = btoa(JSON.stringify({ typ: "JWT", alg: "HS256" }));
const encodedPayload = btoa(JSON.stringify(payload));

// Concatenate the encoded JWT header and payload with a period separator
const encodedToken = `${header}.${encodedPayload}`;

// Sign the encoded JWT using the secret key and the HMAC-SHA256 algorithm
const signature = CryptoJS.HmacSHA256(encodedToken, secretKey);
const signedToken = `${encodedToken}.${CryptoJS.enc.Base64url.stringify(
  signature
)}`;

var access_token = localStorage.getItem("access_token");
// var access_token = [];
var http = {
  async requestVRMURL(walletAddress, tokenId) {
    var requestAddress =
      walletAddress == web3.walletAddress ? "" : walletAddress;
    const requestBody = {
      address: requestAddress,
      token_id: tokenId,
    };

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "ZF-Client-Token": signedToken,
      "ZF-Access-Token": access_token,
    };
    try {
      const response = await axios.post(
        SERVER_URL + ":30001/api/v1/auth/vrm/get",
        requestBody,
        { headers }
      );

      window.open(response.data.url, "_self");
      return response.data;
    } catch (error) {
      console.log(error.response.status);
      if (error.response) {
        if (
          error.response.status == 400 ||
          error.response.status == 401 ||
          error.response.status == 500
        ) {
          var responseData = await this.requestSign(web3.walletAddress);
          await web3.signMessage(responseData);

          this.requestVRMURL(walletAddress, tokenId);
        }
      }
    }
  },

  async getEditionIdList(tokenIdList) {
    try {
      const response = await axios.get(
        SERVER_URL + ":30010/api/v1/revealedId/get?tokenId=" + tokenIdList
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async verifySignedMessage(walletAddress, message) {
    try {
      var verifySignedMessageParameters =
        new VerifySignedMessage.VerifySignedMessageParameters();

      verifySignedMessageParameters.setAddress(walletAddress);
      verifySignedMessageParameters.setMessage(message);
      verifySignedMessageParameters.setAuthtype(1);

      var serialized = verifySignedMessageParameters.serializeBinary();

      var url = SERVER_URL + ":30001/api/v1/auth/web3/sign/message/verify";

      var httpProtoResponse = await this.httpProtoRequest(
        url,
        "post",
        serialized
      );

      if (httpProtoResponse.status == 200) {
        var verifySignedMessageResponse =
          VerifySignedMessage.VerifySignedMessageResponse.deserializeBinary(
            httpProtoResponse.body
          );

        access_token = verifySignedMessageResponse.getAccessToken();

        localStorage.setItem("access_token", access_token);

        return access_token;
      } else {
        console.log(httpProtoResponse.status);
      }
    } catch (error) {
      console.error(error);
    }
  },
  // 54.169.12.15
  async requestSign(walletAddress) { 
    try {
      var RequestSignedMessageParameters =
        new RequestSignedMessage.RequestSignedMessageParameters();

      RequestSignedMessageParameters.setAddress(walletAddress);
      RequestSignedMessageParameters.setAuthtype(1);
      var serialized = RequestSignedMessageParameters.serializeBinary();

      var url = SERVER_URL + ":30001/api/v1/auth/web3/sign/message/new";

      var httpProtoResponse = await this.httpProtoRequest(
        url,
        "post",
        serialized
      );

      if (httpProtoResponse.status == 200) {
        var requestSignedMessageResponse =
          RequestSignedMessage.RequestSignedMessageResponse.deserializeBinary(
            httpProtoResponse.body
          );

        return requestSignedMessageResponse.getMessage();
      }
    } catch (error) {
      console.error(error);
    }
  },

  async httpProtoRequest(apiUrl, method, requestData) {
    try {
      const response = await axios({
        method: method,
        url: apiUrl,
        data: requestData,
        headers: {
          "Content-Type": "application/x-protobuf",
          Accept: "application/x-protobuf",
          "ZF-Client-Token": signedToken,
          "ZF-Access-Token": access_token,
        },
        responseType: "arraybuffer",
      });

      if (response.status == 200) {
        var httpProtoResponse =
          HttpResponseProto.HttpResponse.deserializeBinary(response.data);
        return {
          status: httpProtoResponse.getStatusCode(),
          body: httpProtoResponse.getBody(),
        };
      } else {
        //console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default http;
