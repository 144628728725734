<template>
  <main class="main">
    <section class="team-banner">
      <div class="team-banner__container container">
        <div class="team-banner__wrap">
          <div class="team-banner__img">
            <img src="img/team/hero.jpg" alt="" />
          </div>

          <div class="team-banner__content">
            <div class="team-banner__logo">
              <img src="img/team/hero-logo.png" alt="" />
            </div>

            <div class="team-banner__card card card--brown">
              <div class="card__body">
                <p>
                  Zoofrenz is a project from Zombot Creative Works, Zombot has
                  been building IPs and games since 2013. We specialize in
                  helping the best web2 brands in the world to produce creative
                  content.
                </p>
              </div>

              <a
                href="https://www.zombotcreative.com/"
                class="card__btn card-btn"
                target="_blank"
              >
                Learn more →
              </a>
            </div>
          </div>

          <ul class="team-banner__partners-list partners-list">
            <li>
              <a class="partner-card">
                <img src="img/team/partner-01.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-02.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-03.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-04.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-05.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-06.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-07.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-08.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-09.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-10.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-11.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-12.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-13.png" alt="" />
              </a>
            </li>
            <li>
              <a class="partner-card">
                <img src="img/team/partner-14.png" alt="" />
              </a>
            </li>
          </ul>

          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="team-banner__card card card--brown card--shadow-top">
                <div class="card__body">
                  <p>
                    Nowadays 3D is an expected milestone for NFT projects, but
                    for many project teams in Web3, 3D is uncharted waters.
                    Meanwhile, we’ve been building and rigging 3D models since
                    before the first Bitcoin was mined. Our past clients range
                    from media giants like Disney and Nintendo to hyper-focused
                    game studios like Riot and Bungie. Our mission - and the
                    main reason we started Zoofrenz - is to help legitimize and
                    advance the Metaverse by bringing AAA artistic / technical
                    standards to the Web3 space. We’ve helped shape some of the
                    biggest IPs in the world - now it’s our turn.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="main__section main-section team-section">
      <div class="main-section__container container">
        <div class="main-section__wrap">
          <h2 class="main-section__title section-title section-title--blue">
          <span>Who we are</span>
          </h2>

          <div class="row">
            <div class="col-md-4 col-sm-6 mb-10">
              <div class="collection-card-wrap">
                <div class="collection-card">
                  <div class="collection-card__vrm">
                    <img src="img/3d/alex.png" alt="" />
                  </div>
                  <div class="collection-card__text">
                    <h3
                      class="collection-card__title collection-card__title--left"
                    >
                    <span>Founder</span>
                    </h3>

                    <div class="collection-card__body">
                      <p>
                        Alex Chu is the founder of Zombot Creative, a
                        Taipei-based firm that’s made skins for Destiny,Spell
                        Break, League of Legends: Wild Rift, among others. In
                        early 2021, Alex fell in love with NFTs during an
                        accidental encounter while day-trading meme stocks. He’s
                        been slowly transforming into an ape ever since.
                      </p>
                    </div>
                  </div>
                </div>

                <a
                  v-on:click="openURL('https://twitter.com/MetaOutfitter')"
                  class="collection-card__btn collection-card__btn--theme"
                >
                  MetaOutfitter
                </a>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 mb-10">
              <div class="collection-card-wrap collection-card-wrap--green">
                <div class="collection-card collection-card--green">
                  <div class="collection-card__vrm">
                    <img src="img/3d/obula.png" alt="" />
                  </div>

                  <div class="collection-card__text">
                    <h3
                      class="collection-card__title collection-card__title--left"
                    >
                    <span>CTO</span>
                    </h3>

                    <div class="collection-card__body">
                      <p>
                        Obula is a game industry veteran with over 13 years of
                        dev experience. He's helped studios build different
                        games ranging from 4x strategy games to fighting games.
                        He was previously the Studio lead at AltPlus and CTO at
                        Zombot. In a past life, Obula was an amateur Street
                        Fighter 4 competitor.
                      </p>
                    </div>
                  </div>
                </div>

                <a
                  v-on:click="openURL('https://twitter.com/Obuladon')"
                  class="collection-card__btn collection-card__btn--theme"
                >
                  Obula
                </a>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 mb-10">
              <div class="collection-card-wrap collection-card-wrap--beige">
                <div class="collection-card collection-card--beige">
                  <div class="collection-card__vrm">
                    <img src="img/3d/may.png" alt="" />
                  </div>

                  <div class="collection-card__text">
                    <h3
                      class="collection-card__title collection-card__title--left"
                    >
                    <span>Project Manager</span>
                    </h3>

                    <div class="collection-card__body">
                      <p>
                        May Han currently works as the Design Producer at Zombot
                        Creative. She’s worked on games such as Everedale and
                        League of Legends: Wild Rift. Her dream is to have a
                        Ragdoll cat named NiaNia.
                      </p>
                    </div>
                  </div>
                </div>

                <a
                  v-on:click="openURL('https://twitter.com/niania_m')"
                  class="collection-card__btn collection-card__btn--theme"
                >
                  niania
                </a>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 mb-10">
              <div class="collection-card-wrap collection-card-wrap--pink">
                <div class="collection-card collection-card--pink">
                  <div class="collection-card__vrm">
                    <img src="img/3d/mark.png" alt="" />
                  </div>

                  <div class="collection-card__text">
                    <h3
                      class="collection-card__title collection-card__title--left"
                    >
                    <span>Marketing Director</span>
                    </h3>

                    <div class="collection-card__body">
                      <p>
                        Mark Chu is a Product Manager with a great love of video
                        games and immersive storytelling. In his spare time, he
                        designs ARGs and puzzles for escape rooms.
                      </p>
                    </div>
                  </div>
                </div>

                <a
                  v-on:click="openURL('https://twitter.com/monkixote')"
                  class="collection-card__btn collection-card__btn--theme"
                >
                  Monkixote
                </a>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 mb-10">
              <div class="collection-card-wrap collection-card-wrap--blue">
                <div class="collection-card collection-card--blue">
                  <div class="collection-card__vrm">
                    <img src="img/3d/ryan.png" alt="" />
                  </div>

                  <div class="collection-card__text">
                    <h3
                      class="collection-card__title collection-card__title--left"
                    >
                    <span>Community Manager</span>
                    </h3>

                    <div class="collection-card__body">
                      <p>
                        DARTΞR is a weirdo who's career begin from gaming
                        industry through as a real estate broker. He is also an
                        amateur dart player, but almost retreat from the circle
                        since step in the world of web3.0.
                      </p>
                    </div>
                  </div>
                </div>

                <a
                  v-on:click="openURL('https://twitter.com/rw_886')"
                  class="collection-card__btn collection-card__btn--theme"
                >
                  DARTER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div v-if="viewportWidth <= 975">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "TeamView",
  data() {
    return {
      viewportWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "touch webp";
    } else {
      document.body.className = "pc webp";
    }
    });

    if (window.innerWidth <= 975) {
      document.body.className = "touch webp";
    } else {
      document.body.className = "pc webp";
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "touch webp";
    } else {
      document.body.className = "pc webp";
    }
    });
  },
  methods: {
    openURL: function (Url) {
      // window.open(Url, "_self");
      window.open(Url);
    },
  },
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
