// source: HttpResponse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.Zombot.Networking.Proto.HttpHealthResponse', null, global);
goog.exportSymbol('proto.Zombot.Networking.Proto.HttpResponse', null, global);
goog.exportSymbol('proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode', null, global);
goog.exportSymbol('proto.Zombot.Networking.Proto.HttpResponseError', null, global);
goog.exportSymbol('proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Zombot.Networking.Proto.HttpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Zombot.Networking.Proto.HttpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Zombot.Networking.Proto.HttpResponse.displayName = 'proto.Zombot.Networking.Proto.HttpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Zombot.Networking.Proto.HttpResponseError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Zombot.Networking.Proto.HttpResponseError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Zombot.Networking.Proto.HttpResponseError.displayName = 'proto.Zombot.Networking.Proto.HttpResponseError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Zombot.Networking.Proto.HttpHealthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Zombot.Networking.Proto.HttpHealthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Zombot.Networking.Proto.HttpHealthResponse.displayName = 'proto.Zombot.Networking.Proto.HttpHealthResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Zombot.Networking.Proto.HttpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Zombot.Networking.Proto.HttpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    body: msg.getBody_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Zombot.Networking.Proto.HttpResponse}
 */
proto.Zombot.Networking.Proto.HttpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Zombot.Networking.Proto.HttpResponse;
  return proto.Zombot.Networking.Proto.HttpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Zombot.Networking.Proto.HttpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Zombot.Networking.Proto.HttpResponse}
 */
proto.Zombot.Networking.Proto.HttpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode} */ (reader.readEnum());
      msg.setStatusCode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Zombot.Networking.Proto.HttpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Zombot.Networking.Proto.HttpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode = {
  NONE: 0,
  STATUSCONTINUE: 100,
  STATUSSWITCHINGPROTOCOLS: 101,
  STATUSPROCESSING: 102,
  STATUSEARLYHINTS: 103,
  STATUSOK: 200,
  STATUSCREATED: 201,
  STATUSACCEPTED: 202,
  STATUSNONAUTHORITATIVEINFO: 203,
  STATUSNOCONTENT: 204,
  STATUSRESETCONTENT: 205,
  STATUSPARTIALCONTENT: 206,
  STATUSMULTISTATUS: 207,
  STATUSALREADYREPORTED: 208,
  STATUSIMUSED: 226,
  STATUSMULTIPLECHOICES: 300,
  STATUSMOVEDPERMANENTLY: 301,
  STATUSFOUND: 302,
  STATUSSEEOTHER: 303,
  STATUSNOTMODIFIED: 304,
  STATUSUSEPROXY: 305,
  STATUSTEMPORARYREDIRECT: 307,
  STATUSPERMANENTREDIRECT: 308,
  STATUSBADREQUEST: 400,
  STATUSUNAUTHORIZED: 401,
  STATUSPAYMENTREQUIRED: 402,
  STATUSFORBIDDEN: 403,
  STATUSNOTFOUND: 404,
  STATUSMETHODNOTALLOWED: 405,
  STATUSNOTACCEPTABLE: 406,
  STATUSPROXYAUTHREQUIRED: 407,
  STATUSREQUESTTIMEOUT: 408,
  STATUSCONFLICT: 409,
  STATUSGONE: 410,
  STATUSLENGTHREQUIRED: 411,
  STATUSPRECONDITIONFAILED: 412,
  STATUSREQUESTENTITYTOOLARGE: 413,
  STATUSREQUESTURITOOLONG: 414,
  STATUSUNSUPPORTEDMEDIATYPE: 415,
  STATUSREQUESTEDRANGENOTSATISFIABLE: 416,
  STATUSEXPECTATIONFAILED: 417,
  STATUSTEAPOT: 418,
  STATUSMISDIRECTEDREQUEST: 421,
  STATUSUNPROCESSABLEENTITY: 422,
  STATUSLOCKED: 423,
  STATUSFAILEDDEPENDENCY: 424,
  STATUSTOOEARLY: 425,
  STATUSUPGRADEREQUIRED: 426,
  STATUSPRECONDITIONREQUIRED: 428,
  STATUSTOOMANYREQUESTS: 429,
  STATUSREQUESTHEADERFIELDSTOOLARGE: 431,
  STATUSUNAVAILABLEFORLEGALREASONS: 451,
  STATUSINTERNALSERVERERROR: 500,
  STATUSNOTIMPLEMENTED: 501,
  STATUSBADGATEWAY: 502,
  STATUSSERVICEUNAVAILABLE: 503,
  STATUSGATEWAYTIMEOUT: 504,
  STATUSHTTPVERSIONNOTSUPPORTED: 505,
  STATUSVARIANTALSONEGOTIATES: 506,
  STATUSINSUFFICIENTSTORAGE: 507,
  STATUSLOOPDETECTED: 508,
  STATUSNOTEXTENDED: 510,
  STATUSNETWORKAUTHENTICATIONREQUIRED: 511
};

/**
 * optional HttpStatusCode status_code = 1;
 * @return {!proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.getStatusCode = function() {
  return /** @type {!proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Zombot.Networking.Proto.HttpResponse.HttpStatusCode} value
 * @return {!proto.Zombot.Networking.Proto.HttpResponse} returns this
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes body = 2;
 * @return {!(string|Uint8Array)}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes body = 2;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Zombot.Networking.Proto.HttpResponse} returns this
 */
proto.Zombot.Networking.Proto.HttpResponse.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.toObject = function(opt_includeInstance) {
  return proto.Zombot.Networking.Proto.HttpResponseError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Zombot.Networking.Proto.HttpResponseError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpResponseError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Zombot.Networking.Proto.HttpResponseError}
 */
proto.Zombot.Networking.Proto.HttpResponseError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Zombot.Networking.Proto.HttpResponseError;
  return proto.Zombot.Networking.Proto.HttpResponseError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Zombot.Networking.Proto.HttpResponseError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Zombot.Networking.Proto.HttpResponseError}
 */
proto.Zombot.Networking.Proto.HttpResponseError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Zombot.Networking.Proto.HttpResponseError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Zombot.Networking.Proto.HttpResponseError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpResponseError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode = {
  NONE: 0,
  CLIENTTOKENINVALID: 1001,
  ACCESSTOKENINVALID: 2001,
  PARAMETERINVALID: 3001
};

/**
 * optional ErrorCode code = 1;
 * @return {!proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode}
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.getCode = function() {
  return /** @type {!proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Zombot.Networking.Proto.HttpResponseError.ErrorCode} value
 * @return {!proto.Zombot.Networking.Proto.HttpResponseError} returns this
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Zombot.Networking.Proto.HttpResponseError} returns this
 */
proto.Zombot.Networking.Proto.HttpResponseError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Zombot.Networking.Proto.HttpHealthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Zombot.Networking.Proto.HttpHealthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Zombot.Networking.Proto.HttpHealthResponse}
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Zombot.Networking.Proto.HttpHealthResponse;
  return proto.Zombot.Networking.Proto.HttpHealthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Zombot.Networking.Proto.HttpHealthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Zombot.Networking.Proto.HttpHealthResponse}
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Zombot.Networking.Proto.HttpHealthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Zombot.Networking.Proto.HttpHealthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Zombot.Networking.Proto.HttpHealthResponse} returns this
 */
proto.Zombot.Networking.Proto.HttpHealthResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.Zombot.Networking.Proto);
