<template>
  <main class="main">
    <section class="zoo-banner">
      <div class="zoo-banner__container">
        <div class="zoo-banner__wrap">
          <div class="zoo-banner__media">
            <img src="img/zoolandia/hero.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <div class="zoo-bg">
      <section class="section">
        <div class="section__container container">
          <div class="section__wrap pb-3">
            <div class="row justify-content-center mb-10">
              <div class="col-md-8">
                <h2 class="section__title section-title">
                  <span>Welcome to Zoolandia!</span>
                </h2>
              </div>
            </div>

            <div class="row justify-content-center mb-10">
              <div class="col-md-9">
                <div
                  class="section__card card card--brown card--shadow-top card--gradient-purple"
                >
                  <h3 class="card__title">
                    A place where everyone <span>belongs</span>
                  </h3>

                  <div class="card__body">
                    <p>
                      When we started Zoofrenz, we wanted to create more than
                      just a pfp collection. We wanted to create a world. Enter
                      Zoolandia, where all the diverse Zoofrenz animals call
                      home.
                    </p>
                    <p>
                      Zoolandia encompasses an entire ecosystem of collectibles
                      and utility. From our original Apefrenz NFT collection to
                      our virtual gathering platform Third Space, this project
                      spans every corner of Web3 and we’re constantly exploring
                      new areas for more!
                    </p>
                    <p>
                      Who knows what surprises we’ll uncover together in
                      Zoolandia?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-5 mb-4">
                <img src="img/zoolandia/img-card.png" alt="" />
              </div>

              <div class="col-md-7 mb-4">
                <div class="section__card card card--green card--vertical">
                  <h3 class="card__title">
                    APE INTO A <br />WHOLE NEW<span> WORLD</span>
                  </h3>

                  <div class="card__body">
                    <p>
                      The Apefrenz are the most advanced species inhabiting
                      Zoolandia. Without their Pioneer spirit, none of this
                      would be possible.
                    </p>
                    <p>
                      6,666 Apefrenz were chosen to become a part of our first
                      PFP collection, and holding one is the key to unlocking
                      everything Zoolandia has to offer.
                    </p>
                  </div>

                  <a
                    href="https://discord.com/invite/d8zQqArnwD"
                    class="card__btn card-btn"
                    target="_blank"
                  >
                    Join the Zoofrenz Family today! →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Coin -->
      <section class="zoo-coin">
        <div class="zoo-coin__container container">
          <div class="zoo-coin__wrap">
            <div class="zoo-coin__row">
              <div class="zoo-coin__col">
                <div
                  class="zoo-coin__card card card--purple card--gradient-purple"
                >
                  <h3 class="card__title">
                    $FRENSHIP IS FREE WEN <br /><span>YOU HAVE FRENZ</span>
                  </h3>

                  <div class="card__body">
                    <p>
                      $FRENSHIP is the central coin powering the Zoofrenz
                      economy. Each Apefrenz generates $FRENSHIP on a daily
                      basis, with rarer Apefrenz generating more $FRENSSHIP.
                    </p>
                  </div>

                  <a
                    v-on:click="
                      openURL(
                        'https://zoofrenz.gitbook.io/info/frenship/what-is-usdfrenship'
                      )
                    "
                    class="card__btn card__btn--absolute card-btn"
                  >
                    Learn more about our $FRENSHIP tokens →
                  </a>
                </div>
              </div>

              <div class="zoo-coin__col">
                <div class="zoo-coin__img">
                  <img src="img/zoolandia/coin.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Img Card -->
      <section class="zoo-img-card">
        <div class="zoo-img-card__container container">
          <div class="zoo-img-card__wrap">
            <div class="zoo-img-card__row">
              <div class="zoo-img-card__col">
                <div class="zoo-img-card__img">
                  <img src="img/zoolandia/pioneers.jpg" alt="" />
                </div>
              </div>

              <div class="zoo-img-card__col">
                <div class="zoo-img-card__card card card--red">
                  <h3 class="card__title">
                    <span>FIRST</span> CLASS PERKS FOR <br />TRUE PIONEERS
                  </h3>

                  <div class="card__body">
                    <p>
                      The word ‘First’ in our First Class Pass doesn’t mean
                      you’re sitting at the top - it means you’re leading the
                      pack. First Class Pass holders will receive exclusive
                      perks, merch, and early access to what we’re working on in
                      exchange for venturing with us into uncharted territory.
                    </p>
                  </div>

                  <a
                    v-on:click="
                      openURL(
                        'https://opensea.io/collection/zoofrenz-first-class-pass'
                      )
                    "
                    class="card__btn card-btn"
                  >
                    Purchase a First Class Pass →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="section__container container">
          <div class="section__wrap">
            <div class="section__img text-center mb-4">
              <img src="img/zoolandia/flow.png" alt="" />
            </div>

            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="card card--brown">
                  <h3 class="card__title">AWAKEN TO A <span>NEW</span> YOU</h3>

                  <div class="card__body">
                    <p>
                      Using Float Tanks, Apefrenz can be Awakened to their true
                      potential. Only by Awakening your OG Apefrenz is it
                      possible to obtain an Apefrenz of the highest rarity.
                    </p>
                  </div>

                  <a
                    v-on:click="
                      openURL(
                        'https://opensea.io/assets/ethereum/0x7f44572988f9368c3ce3c32697961b1896f941a3/1'
                      )
                    "
                    class="card__btn card-btn"
                  >
                    Purchase a Float Tank →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="zoo-ascend">
        <div class="zoo-ascend__container container">
          <div class="zoo-ascend__wrap">
            <div class="zoo-ascend__row">
              <div class="zoo-ascend__col">
                <div class="zoo-ascend__card card card--green">
                  <h3 class="card__title">
                    <span>ASCEND</span> TO A NEW DIMENSION
                  </h3>

                  <div class="card__body">
                    <p>
                      Every Zoofrenz 2D PFP holder automatically has access to a
                      3D Avatar. Each avatar comes Metaverse-ready, and grants
                      access to our Third Space platform.
                    </p>
                  </div>

                  <a
                    v-on:click="
                      openURL(
                        'https://zoofrenz.gitbook.io/info/3d-avatars/what-are-3d-avatars'
                      )
                    "
                    class="card__btn card-btn"
                  >
                    Learn more about our 3D Avatars →
                  </a>
                </div>
              </div>

              <div class="zoo-ascend__col">
                <div class="zoo-ascend__img">
                  <img src="img/zoolandia/ascend.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Space -->
      <section class="zoo-space">
        <div class="zoo-space__container container">
          <div class="zoo-space__wrap">
            <div class="row flex-wrap-reverse">
              <div class="col-md-6 col-xs-7">
                <div class="zoo-space__img">
                  <img src="img/zoolandia/space.jpg" alt="" />
                </div>
              </div>

              <div class="col-md-6 col-xs-5">
                <div class="zoo-space__text">
                  <h2 class="zoo-space__title">
                    A <span>NEW</span> SPACE TO FILL THE VOID
                  </h2>
                </div>
              </div>
            </div>

            <div class="row justify-content-end">
              <div class="col-md-8">
                <div class="card card--red">
                  <div class="card__body">
                    <p>
                      We got tired of hosting Web3 community events on ‘old
                      school’ Web2 platforms, so we made our own virtual
                      gathering space! Third Space is open to all 3D Zoolandia
                      residents, and will soon be welcoming our partner projects
                      as well!
                    </p>
                  </div>

                  <a
                    v-on:click="
                      openURL(
                        'https://zoofrenz.gitbook.io/info/third-space/what-is-third-space'
                      )
                    "
                    class="card__btn card-btn"
                  >
                    Learn more about Third Space →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Find -->
    <section class="zoo-find">
      <img
        src="img/zoolandia/find-decor-01.png"
        alt=""
        class="zoo-find__decor zoo-find__decor--01"
      />

      <img
        src="img/zoolandia/find-decor-02.png"
        alt=""
        class="zoo-find__decor zoo-find__decor--02"
      />

      <div class="zoo-find__container container">
        <div class="zoo-find__wrap">
          <div class="zoo-find__row">
            <div class="zoo-find__col">
              <div class="zoo-find__text">
                <h2 class="zoo-find__title">FIND YOURSELF IN THE ZOO</h2>

                <div class="zoo-find__body">
                  <p>
                    Our mindful Apefrenz are only the beginning! What makes
                    Zoolandia unique are the diverse species and cultures that
                    inhabit it. Each new animal collection we release will have
                    their own distinct persona and mechanics.
                  </p>
                </div>
              </div>
            </div>

            <div class="zoo-find__col">
              <div class="zoo-find__img">
                <img src="img/zoolandia/find-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div v-if="viewportWidth <= 975">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "FeaturesView",
  mounted() {
    if (window.innerWidth <= 975) {
      document.body.className = "zoo-page touch webp";
    } else {
      document.body.className = "zoo-page pc webp";
    }
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "zoo-page touch webp";
    } else {
      document.body.className = "zoo-page pc webp";
    }
    });
  },
  methods: {
    openURL: function (Url) {
      // window.open(Url, "_self");
      window.open(Url);
    },
  },
  data() {
    return {
      viewportWidth: window.innerWidth,
    };
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
      if (window.innerWidth <= 975) {
      document.body.className = "zoo-page touch webp";
    } else {
      document.body.className = "zoo-page pc webp";
    }
    });
  }
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
