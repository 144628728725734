<template>
  <header class="header">
    <div class="header__container container">
      <div class="header__wrap">
        <router-link to="/" v-on:click="clickMenu" class="header__logo">
          <img src="img/logo.png" alt="Zoofenz" />
        </router-link>

        <div class="header__menu menu">
          <nav class="menu__body" data-menu>
            <span aria-hidden="true"></span>
            <ul>
              <li>
                <router-link to="/Features" v-on:click="clickMenu"
                  >Features</router-link
                >
              </li>
              <li>
                <router-link to="/Merch" v-on:click="clickMenu"
                  >Merch</router-link
                >
              </li>
              <li :class="{ 'active': isSublistActive }">                
                <a v-on:click="clickMore">More</a>
                <span class="menu__arrow"></span>

                <ul class="menu__sublist">
                  <li>
                    <router-link to="/Why" v-on:click="clickMenu"
                      >Why</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/Team" v-on:click="clickMenu"
                      >Team</router-link
                    >
                  </li>
                  <li>
                    <a
                      href="https://legacy.zoofrenz.com/awaken"
                      v-on:click="clickMenu"
                      >Awaken</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://legacy.zoofrenz.com/migrate"
                      v-on:click="clickMenu"
                      >Migrate</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://legacy.zoofrenz.com/search"
                      v-on:click="clickMenu"
                      >Checker</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://legacy.zoofrenz.com/list"
                      v-on:click="clickMenu"
                      >Token Claim</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/Claim" v-on:click="clickMenu"
                  >3D Claim</router-link
                >
              </li>
            </ul>

            <div class="menu__btn-box">
              <a
                v-if="!walletConnected"
                v-on:click="connectWallet()"
                class="btn"
                >Connect</a
              >
              <a
                v-else
                v-on:click="connectWallet()"
                style="visibility: hidden"
                class="connected_btn"
                >Connected</a
              >

              <ul class="menu__soc soc">
                <li>
                  <a
                    href="https://twitter.com/zoofrenzNFT"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="icon-twitter"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/zoofrenz.eth/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="icon-instagram"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/d8zQqArnwD"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="icon-discord"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://opensea.io/collection/zoofrenz-apefrenz-2-0"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="icon-ship"
                  ></a>
                </li>
              </ul>
            </div>
          </nav>

          <button
            class="menu__btn"
            data-burger
            aria-label="open menu"
            title="menu"
          >
            <span aria-hidden></span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Web3 from "../web3/web3";

export default {
  name: "HeaderView",
  methods: {
    updateWalletConnected: function () {
      this.walletConnected = window.walletConnected;
    },
    connectWallet: function () {
      this.clickMenu();
      Web3.connectWallet();
    },
    clickMenu: function () {
      if (this.menuBtn.classList.contains("active")) {
        this.menuBtn.classList.remove("active");
        this.menuBody.classList.remove("active");
      }
    },  
    clickMore: function () {
      this.isSublistActive = !this.isSublistActive;  
    },  
  },
  data() {
    return {
      isSublistActive : false,
      walletConnected: window.walletConnected,
    };
  },
  mounted() {

    window.walletConnected = true;
    this.updateWalletConnected();
    Web3.initOnboard();
    this.updateWalletConnected();
    window.addEventListener("wallet-connect-event", this.updateWalletConnected);

    this.menuBtn = document.querySelector("[data-burger]");
    this.menuBody = document.querySelector("[data-menu]");
    if (this.menuBtn && this.menuBody) {
      this.menuBtn.addEventListener("click", (e) => {
        if (!e.target.classList.contains("active")) {
          this.menuBtn.classList.add("active");
          this.menuBody.classList.add("active");
          // disableScroll();
        } else {
          this.menuBtn.classList.remove("active");
          this.menuBody.classList.remove("active");
          // enableScroll();
        }
      });

      // if (isMobile()) {
      //   initMenuArrows();
      // }
    }
  },
  beforeUnmount() {
    window.removeEventListener(
      "wallet-connect-event",
      this.updateWalletConnected
    );
  },
  openURL: function (Url) {
    // window.open(Url, "_self");
    window.open(Url);
  },
};
</script>

<style scoped>
@import "../../public/css/style.min.css";
</style>
